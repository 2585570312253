<template lang="pug">
draggable.drag-area(:list="steps" tag='ul' :group="!isMissions ? { name: 'step' } : { name: 'mission' }" :transitionMode="true" @end="!isMissions ? updateIndexStep() : updateIndexMission()")
    li(v-for='(step, index) in steps' :key="isMissions ? step.mission.id+' '+index : step.step.id+' '+index")
      b-button.cursor-grab.steps(variant="primary" :class="step.isDone ? 'isDone' : ''")
          .d-flex.align-items-center
              b-checkbox(v-model="step.isDone" button-variant="primary" @change="!isMissions ? checkedStep(step) : ''" v-if="isEdit")
              p.mb-0(style="text-align: start") {{ isMissions ? step.mission.label : step.step.label}}
          .d-flex
              feather-icon.ml-1.cursor-pointer(icon="EditIcon" @click="switchEditStepOrMission(step, index)")
              feather-icon.cursor-pointer(icon="Trash2Icon" @click="switchDeleteStepOrMission(step, index)")
      b-button.btn-addMission(variant="outline-primary" v-if="(Number.isInteger(index) && index === steps.length-1) && !step.missions ||(step.missions && step.missions.length === 0)" @click="step.missions ? $emit('popup-mission', {stepIndex: index}) : $emit('popup-mission', {stepIndex: stepIndex})")
          | Ajouter une tâche
          feather-icon(icon="PlusIcon")
      nested-draggable.missions(v-if="!isMissions" :step="step" :stepIndex="index" :steps="step.missions" @editMission="(id, stepIndex, index) => $emit('editMission', id, stepIndex, index)" @deleteMissionInSteps="(stepIndex, id) => $emit('deleteMissionInSteps', stepIndex, id)" @popup-mission="({step, stepIndex, index}) => $emit('popup-mission', {step, stepIndex, index})" :isMissions="true" :isEdit="isEdit ? true : false")
</template>

<script>
import draggable from "vuedraggable";
import { mapActions } from "vuex";

export default {
  props: {
    steps: {
      type: Array,
      required: true,
    },
    missions: {
      type: Array,
    },
    isMissions: {
      type: Boolean,
      required: true,
    },
    isEdit: {
      type: Boolean,
    },
    stepIndex: {
      type: Number,
      required: false,
    },
    step: {
      required: false,
    },
  },
  data() {
    return {
      isActive: false,
      isMission: false,
    };
  },
  methods: {
    updateIndexMission() {
      const missions = [];
      for (let i = 0; i < this.step.missions.length; i++) {
        const mission = this.step.missions[i];
        missions.push({ id: mission.mission.id, index: i });
      }
      this.updateMissionIndex(missions);
    },
    updateIndexStep() {
      const steps = [];
      for (let i = 0; i < this.steps.length; i++) {
        const step = this.steps[i];
        steps.push({ id: step.step.id, index: i });
      }
      this.updateStepIndex(steps);
    },
    checkedStep(step) {
      if (step.isDone) {
        step.missions.map((elem) => (elem.isDone = true));
      } else {
        step.missions.map((elem) => (elem.isDone = false));
      }
    },
    popupMission(item, stepIndex, isCommon, index) {
      if (item.missions) {
        this.$emit("popup-mission", { step: item, stepIndex, isCommon, index });
      } else {
        this.$emit("popup-mission", { step: item, stepIndex, isCommon, index });
      }
    },
    switchDeleteStepOrMission(elem) {
      if (elem.missions) {
        this.DeleteStep(elem.step.label, elem.step.id);
      } else {
        this.DeleteMission(elem.mission.label, elem.mission.id);
      }
    },
    DeleteStep(label, id) {
      this.$bvModal
        .msgBoxConfirm("Vous ne pourrez plus retrouver cette étape.", {
          title: `Êtes-vous sûr de vouloir supprimer l'étape ${label} ?`,
          size: "sm",
          okVariant: "danger",
          okTitle: "Supprimer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$emit("deleteStepInSteps", id);
            this.$nextTick(() => {
              this.$bvModal.hide("popup-step");
            });
          }
        });
    },
    DeleteMission(label, id) {
      this.$bvModal
        .msgBoxConfirm("Vous ne pourrez plus retrouver cette tâche.", {
          title: `Êtes-vous sûr de vouloir supprimer la tâche ${label} ?`,
          size: "sm",
          okVariant: "danger",
          okTitle: "Supprimer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$emit("deleteMissionInSteps", this.stepIndex, id);
            this.$nextTick(() => {
              this.$bvModal.hide("popup-step");
            });
          }
        });
    },
    switchEditStepOrMission(elem, index) {
      if (elem.missions) {
        this.$emit("editStep", { id: elem.step.id, index });
      } else {
        this.$emit("editMission", { element: elem, stepIndex: this.stepIndex, index: index });
      }
    },
    ...mapActions(["updateMissionIndex", "updateStepIndex"]),
  },
  computed: {
    interventionForm: {
      get() {
        return this.$store.getters.intervention;
      },
      set(value) {
        return this.$store.commit("SET_INTERVENTION", value);
      },
    },
  },
  components: {
    draggable,
  },
  name: "nested-draggable",
};
</script>

<style lang="scss">
.drag-area {
  width: 20vw;

  li {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .steps.btn {
    display: flex;
    flex-direction: row;
    background-color: #0077b6 !important;
    justify-content: space-between;
    align-items: center;
    width: 20vw;
    margin-top: 1rem;

    &.isDone {
      background-color: #b4b1b1 !important;
      text-decoration: line-through;
      border-color: #b4b1b1 !important;
    }
  }

  .btn.btn-addMission {
    background-color: transparent !important;
    margin-top: 5px;
    width: 18vw;
    display: flex;
    justify-content: space-between;

    &.invisible {
      display: none;
    }
  }

  .steps.cursor-grab {
    cursor: grab !important;
  }

  .cursor-pointer {
    cursor: pointer !important;
  }

  .missions li {
    margin-top: 5px;

    .btn {
      margin: 0;
      width: 18vw;
      text-align: unset;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #0096c7 !important;

      &.btn-addMission {
        background-color: transparent !important;
        margin-top: 5px;
      }

      &.isDone {
        background-color: #b4b1b1 !important;
        text-decoration: line-through;
        border-color: #b4b1b1 !important;
      }
    }
  }
}
</style>
