<template>
  <b-modal id="popup-step" ref="popupStep" no-close-on-backdrop centered="centered" :title="(step.id ? 'Modifier' : 'Ajouter') + ` une étape`" @ok="create">
    <div v-if="isLoadingCommonStepsList || isLoadingCommonStepsListArchived">
      <div class="text-center flex-center">
        <div class="loading-bg-inner">
          <div class="loader">
            <div class="outer"></div>
            <div class="middle"></div>
            <div class="inner"></div>
          </div>
        </div>
        <div class="mt-5">
          <br /><br /><br />
          Chargement...
        </div>
      </div>
    </div>
    <validation-observer v-else ref="formStep">
      <b-tabs @input="initializeForm">
        <b-tab no-body :title="step.commonStepId ? 'Modifier votre étape' : 'Créez votre étape'" active>
          <b-row>
            <b-col cols="12" class="d-flex">
              <b-form-group label="Libellé *" label-for="Libellé" style="flex: 1">
                <validation-provider #default="{ errors }" name="label" rules="required">
                  <b-form-input
                    :state="errors.length > 0 ? false : null"
                    id="label"
                    v-model="step.label"
                    class="not-autocomplete"
                    autocomplete="nope"
                    aria-autocomplete="nope"
                    v-on:keyup.enter="create"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab no-body title="Sélectionnez une étape courante">
          <b-row>
            <b-col cols="12" class="d-flex">
              <b-form-group label="Étape courante" label-for="Libellé" style="flex: 1">
                <v-select id="intervention" :reduce="(type) => type.id" v-model="step.commonStepId" :options="commonStepsList" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </validation-observer>
    <template #modal-footer="{ ok, cancel }">
      <div class="w-100 mx-0">
        <b-button class="float-left" v-if="archive || !step.commonStepId" variant="outline-danger" @click="deleteStepLocal(step.id, step.label)"
          ><feather-icon icon="Trash2Icon"
        /></b-button>
        <b-button class="float-left" v-else variant="outline-danger" @click="archiveStepLocal(step.id, step.label)"
          ><feather-icon icon="ArchiveIcon"
        /></b-button>
        <b-button class="float-right" v-if="!archive" variant="primary" @click="ok()">{{ step.id ? "Modifier" : "Ajouter" }}</b-button>
        <b-button class="float-right" v-if="archive" variant="warning" @click="restoreStepLocal(step.id, step.label)">Restaurer</b-button>
        <b-button class="float-right mr-1" variant="outline-primary" @click="cancel()">Annuler</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver, configure, localize } from "vee-validate";
import { required, url, email } from "@validations";
import { mapGetters, mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
import "vue-swatches/dist/vue-swatches.css";
import vSelect from "vue-select";

configure({
  // Generates an English message locale generator
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

export default {
  data() {
    return {
      required,
      url,
      email,
      step: {
        id: 0,
        commonStepId: null,
        label: "",
        isCommon: false,
        missions: [],
      },
      indexOfStep: null,
      archive: false,
    };
  },
  methods: {
    popupCreateStepEvent(id, index) {
      this.fetchCommonStepsList();
      this.fetchMissionsList();
      this.indexOfStep = index;
      if (id > 0) {
        this.fetchStep(id).then((res) => {
          if (res.isCommon) {
            this.step.commonStepId = res.id;
            this.stepCopy.commonStepId = res.id;
            this.step.id = res.id;
            this.stepCopy.id = res.id;
          } else {
            this.step = res;
            this.stepCopy = res;
          }
        });
      } else this.initializeForm();
    },
    initializeForm() {
      this.step = {
        commonStepId: null,
        label: "",
        isCommon: false,
        missions: [],
      };
    },
    deleteStepLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm("Vous ne pourrez plus restaurer cette étape en consultant la liste de vos archives.", {
          title: `Êtes-vous sûr de vouloir supprimer l'étape' ${label} ?`,
          size: "sm",
          okVariant: "danger",
          okTitle: "Supprimer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteStep(id).then(() => {
              this.$emit("deleteStepInSteps", id);
            });
            this.$nextTick(() => {
              this.$bvModal.hide("popup-step");
            });
          }
        });
    },
    archiveStepLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm("Vous pourrez restaurer cette étape en consultant la liste de vos archives.", {
          title: `Êtes-vous sûr de vouloir archiver l'étape ${label} ?`,
          size: "sm",
          okVariant: "warning",
          okTitle: "Archiver",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.archiveStep(id).then(() => {
              this.$emit("deleteStepInSteps", id);
            });
            this.$nextTick(() => {
              this.$bvModal.hide("popup-step");
            });
          }
        });
    },
    restoreStepLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm("Vous pourrez restaurer cette étape en consultant la liste de vos archives.", {
          title: `Êtes-vous sûr de vouloir restauré l'étape ${label} ?`,
          size: "sm",
          okVariant: "warning",
          okTitle: "Restaurer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.restoreStep(id);
            this.$nextTick(() => {
              this.$bvModal.hide("popup-step");
            });
          }
        });
    },
    create(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.formStep.validate().then((success) => {
        if (success) {
          if (!this.step.id) {
            // création d'un étape
            this.createStep({ label: this.step.label, isCommon: false }).then((res) => {
              if (this.step.commonStepId) {
                const stepSelected = this.commonStepsList.find((elem) => elem.id === this.step.commonStepId);
                const missionToStepSelected = this.missionsList.filter((elem) =>
                  stepSelected.missions
                    .map((elem) => {
                      return elem.mission.id;
                    })
                    .includes(elem.id)
                );
                missionToStepSelected.map((elem) => {
                  this.createMission({
                    label: elem.label,
                    isCommon: false,
                    missionTypeId: elem.missionType.id,
                    deviceId: elem.device && elem.device !== null ? elem.device.id : null,
                  }).then((result) => {
                    res.missions.push({ stepId: res.id, mission: { ...result }, isDone: false });
                  });
                });
                this.$emit("popupStepClose", { data: res });
                this.$nextTick(() => {
                  this.$bvModal.hide("popup-step");
                });
              } else {
                this.$emit("popupStepClose", { data: res });
                this.$nextTick(() => {
                  this.$bvModal.hide("popup-step");
                });
              }
            });
          } else {
            // édition d'une étape
            this.updateStep({ label: this.step.label, isCommon: false, id: this.step.id }).then((res) => {
              const stepSelected = this.commonStepsList.find((elem) => elem.id === this.step.commonStepId);
              const missionToStepSelected = this.missionsList.filter((elem) =>
                stepSelected.missions
                  .map((elem) => {
                    return elem.mission.id;
                  })
                  .includes(elem.id)
              );
              missionToStepSelected.map((elem) => {
                this.createMission({
                  label: elem.label,
                  isCommon: false,
                  missionTypeId: elem.missionType.id,
                  deviceId: elem.device && elem.device !== null ? elem.device.id : null,
                }).then((result) => {
                  res.missions.push({ stepId: res.id, mission: { ...result }, isDone: false });
                });
              });
              this.$emit("popupStepClose", { data: res, update: true, index: this.indexOfStep });
              this.$nextTick(() => {
                this.$bvModal.hide("popup-step");
                this.initializeForm();
              });
            });
          }
        }
      });
    },
    popupCreateMission(id, isCommonMission) {
      this.$bvModal.show("popup-mission");
      this.$refs["popupMission"].popupCreateMissionEvent(id, null, isCommonMission);
    },
    popupEditMission(id, index, update) {
      this.$bvModal.show("popup-mission");
      this.$refs["popupMission"].popupEditMissionEvent(id, index, update);
    },
    ...mapActions([
      "createStep",
      "createMission",
      "updateStep",
      "updateMissionStep",
      "deleteStep",
      "deleteMission",
      "restoreStep",
      "fetchStep",
      "archiveStep",
      "fetchCommonStepsList",
      "fetchCommonStepsListArchived",
      "fetchMissionsList",
    ]),
  },
  computed: {
    ...mapGetters([
      "isCreatingAccessMethodType",
      "stepsList",
      "missionsList",
      "commonStepsList",
      "isLoadingCommonStepsList",
      "isLoadingCommonStepsListArchived",
    ]),
  },

  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
  watch: {
    "step.commonStepId": function (newValue, oldValue) {
      if (newValue && newValue != oldValue) {
        const stepSelected = this.commonStepsList.find((elem) => elem.id === newValue);
        this.step.label = stepSelected.label;
        this.step.isCommon = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.missionList {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #0096c7;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  margin: 5px 0;

  div svg {
    cursor: pointer;

    &:nth-child(2) {
      margin-left: 5px;
    }
  }
}
</style>
